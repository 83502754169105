import styles from './style.module.scss';
import { ROUTES } from 'shared/constants';
import { Link } from 'react-router-dom';
import { UITypography } from 'shared/ui';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-icon.svg';
import { useTranslation } from 'react-i18next';

type LeagueProps = {
  title: string;
  img: string;
};

export const League = ({ title, img }: LeagueProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.leagueContainer}>
      <div className={styles.counter}>
        <div className={styles.leagueTitle}>
          <img src={img} alt="" width={40} height={40} />
          <UITypography variant="H2">{title}</UITypography>
        </div>
      </div>

      <Link to={ROUTES.FAQ}>
        <UITypography fontWeight="bold" iconRight={<ArrowDownIcon className={styles.arrowRight} />}>
          {t('friends.league_advantages')}
        </UITypography>
      </Link>
    </div>
  );
};
