import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { finishSession, startSession } from 'shared/api';
import { ROUTES } from 'shared/constants';
import { useGameStore, useGlobalStore } from 'shared/store';
import { checkEarnedCoins, handleErrorWithAlert, handleErrorStatus } from 'shared/utils';
import { leaveSession } from '../utils';

type StopGameParams = {
  limitReached: boolean;
  _currCoins: number;
  failedCoins: number;
  _catchedBees: number;
  _sessionId: string;
};

export function useGame() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const donutsCleared = useGameStore((state) => state.donutsCleared);
  const setSessionId = useGameStore((state) => state.setSessionId);
  const updateUser = useGlobalStore((state) => state.updateUser);
  const increaseCurrentLimit = useGlobalStore((state) => state.increaseCurrentLimit);
  const resetGameState = useGameStore((state) => state.resetGameState);

  const startGame = async () => {
    try {
      const res = await startSession();
      setSessionId(res.data.uuid);
      updateUser({ last_start: res.data.last_start });
    } catch (error) {
      navigate(ROUTES.MAIN, { replace: true });
      handleErrorWithAlert(error, t('alerts.something_went_wrong'));
    }
  };

  const stopGame = async ({
    _currCoins,
    failedCoins,
    _catchedBees,
    limitReached,
    _sessionId,
  }: StopGameParams) => {
    try {
      if (!_sessionId) return;

      const res = await finishSession(_sessionId, _currCoins, _catchedBees, failedCoins);
      increaseCurrentLimit(_currCoins);
      updateUser({ coins: res.data.total_coins });
      leaveSession(navigate, limitReached, _currCoins);
    } catch (error) {
      handleErrorStatus(
        error,
        409,
        () => {
          alert(t('alerts.you_already_started_session'));
          navigate(ROUTES.MAIN, { replace: true });
        },
        () => {
          alert(t('alerts.something_went_wrong'));
          navigate(ROUTES.MAIN, { replace: true });
        },
      );
    } finally {
      resetGameState();
    }
  };

  useEffect(() => {
    startGame();
  }, []);

  useEffect(() => {
    const handleFinishGame = async () => {
      const sessionId = useGameStore.getState().sessionId;

      if (!donutsCleared || !sessionId) return;

      const currCoins = useGameStore.getState().currCoins;
      const { current_limit, daily_bonus_limit, daily_limit } = useGlobalStore.getState().user;

      const isLimitReached = currCoins + current_limit >= daily_limit + daily_bonus_limit;

      const earnedCoins = checkEarnedCoins({
        dailyLimit: daily_limit + daily_bonus_limit,
        currCoins: currCoins,
        currentLimit: current_limit,
        limitReached: isLimitReached,
      });

      await stopGame({
        _currCoins: earnedCoins,
        failedCoins: useGameStore.getState().failedDonuts,
        _catchedBees: useGameStore.getState().catchedBees,
        limitReached: isLimitReached,
        _sessionId: sessionId,
      });
    };
    handleFinishGame();
  }, [donutsCleared]);

  return {
    startGame,
    stopGame,
  };
}
