import { Link } from 'react-router-dom';
import { ADS_BLOCK_ID, GAME_COIN_SHORTCUT, ROUTES } from 'shared/constants';
import { UITypography } from 'shared/ui';
import { formatLargeNumber } from 'shared/utils';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { useGameStore, useGlobalStore } from 'shared/store';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-icon.svg';
import { ShowAdsButton } from 'components';

export const Limit = () => {
  const { t } = useTranslation();

  const user = useGlobalStore((state) => state.user);
  const dailyBonusLimit = useGameStore((state) => state.settings.daily_bonus_limit);
  const maxAdsCount = useGameStore((state) => state.settings.max_count_ads);
  const botInfoLang = useGlobalStore((state) => state.botInfo.lang);

  return (
    <div className={styles.limitRoot}>
      <img src="/three-small-donuts.png" width={40} height={40} />
      <div className={styles.limitContainer}>
        <div className={styles.limit}>
          <UITypography component="p">{t('main_screen.your_limit')}</UITypography>

          <UITypography
            component="p"
            fontWeight="bold"
          >{`${formatLargeNumber(user.current_limit)}/${formatLargeNumber(user.daily_limit + user.daily_bonus_limit)} ${GAME_COIN_SHORTCUT}`}</UITypography>
        </div>

        <Link to={ROUTES.FRIENDS}>
          <UITypography iconRight={<ArrowDownIcon className={styles.arrowRight} />}>
            {t('main_screen.increase_limit')}
          </UITypography>
        </Link>
      </div>

      {user.current_count_ads < maxAdsCount && (
        <ShowAdsButton
          rewardType="daily_limit_increase"
          blockId={ADS_BLOCK_ID[botInfoLang]}
          textRight={`+${dailyBonusLimit}`}
          className={styles.showAdsButton}
        />
      )}
    </div>
  );
};
