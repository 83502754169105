import { useGameStore, useGlobalStore } from 'shared/store';
import styles from './style.module.scss';
import { useEffect, useRef } from 'react';
import { DeadFly } from 'components/lottie';

type FreezeOverlayProps = {
  freezeCooldown: number;
};

export const FreezeOverlay = ({ freezeCooldown }: FreezeOverlayProps) => {
  const appVisible = useGlobalStore((state) => state.appVisible);
  const frozen = useGameStore((state) => state.frozen);
  const setFrozen = useGameStore((state) => state.setFrozen);
  const timeotRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const effect = () => {
      if (!appVisible) {
        timeotRef.current && clearTimeout(timeotRef.current);
        return;
      }

      if (frozen) {
        timeotRef.current = setTimeout(() => {
          setFrozen(false);
        }, freezeCooldown);
      }
    };
    effect();

    return () => {
      timeotRef.current && clearTimeout(timeotRef.current);
    };
  }, [frozen, freezeCooldown, appVisible]);

  return (
    frozen && (
      <div className={styles['overlay']}>
        <DeadFly />
      </div>
    )
  );
};
