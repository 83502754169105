import styles from './style.module.scss';
import { UIButton, UITypography } from 'shared/ui';
import { useInitData } from '@vkruglikov/react-telegram-web-app';
import { useGlobalStore } from 'shared/store';
import { useTranslation } from 'react-i18next';
import { formatLargeNumber } from 'shared/utils';

type UserWidgetProps = {
  username?: string;
  avatar?: string;
  isReferral?: boolean;
  isApproved?: boolean;
  percengage?: number;
  showCurrency?: boolean;
};

export const UserWidget = ({
  username,
  avatar,
  isReferral,
  isApproved,
  percengage,
}: UserWidgetProps) => {
  const user = useGlobalStore((state) => state.user);
  const [initDataUnsafe] = useInitData();
  const tgUser = initDataUnsafe?.user;

  const _username = username ? username : user.username || user.tg_user_id;
  const _photo = (avatar || tgUser?.photo_url || '/bobe-avatar-placeholder.png') as string;

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {!isReferral && (
          <div className={styles.avatar}>
            <img src={_photo} alt="" className={styles.avatarImage} />
          </div>
        )}

        <UITypography fontWeight="bold" className={styles.username}>
          {_username}
        </UITypography>
      </div>

      {isReferral && <TokensCount isApproved={isApproved} percengage={percengage} />}
    </div>
  );
};

const TokensCount = ({ isApproved, percengage }: UserWidgetProps) => {
  const { t } = useTranslation();
  return isApproved ? (
    <UITypography fontWeight="bold">{`+${formatLargeNumber(percengage || 0, 1)}%`}</UITypography>
  ) : (
    <UIButton disabled size="small2" fontVariant="Button2">
      {t('common.pending')}
    </UIButton>
  );
};
