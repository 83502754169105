import { useEffect, useState } from 'react';
import { REFS_LIST_LIMIT } from 'shared/constants';
import { getRefs } from '../queries/referrals';
import { GetRefsResponse } from 'shared/types';
import { useReferralsStore } from 'shared/store';

export const useFetchRefs = () => {
  const setRefsStore = useReferralsStore((state) => state.setRefsStore);

  const [refs, setRefs] = useState<GetRefsResponse>({ count: 0, data: [] });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRefs = async () => {
      try {
        setLoading(true);
        const refsRes = await getRefs(REFS_LIST_LIMIT, 0);
        setRefs({
          count: refsRes.data.count,
          data: refsRes.data.data,
        });
        setRefsStore({
          count: refsRes.data.count,
          data: refsRes.data.data,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchRefs();
  }, []);

  return { loading, refs };
};
