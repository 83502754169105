import { useCallback, useEffect, useRef, useState, useTransition } from 'react';
import {
  DONUT_TYPES,
  DONUT_SIZES,
  DEFAULT_FINGER_SLIDER_CONFIG,
  DONUT_AWARD_VALUES,
} from 'shared/constants';
import { getRandomBoolean, getRandomValueWithChance, randomNumberBetween } from 'shared/utils';
import { useGameStore, useGlobalStore } from 'shared/store';
import { DonutEntity, DonutSize } from 'shared/types';
import { v4 } from 'uuid';
import { clearEntityQueue } from '../utils';

export const useDonuts = () => {
  const [_, startTransition] = useTransition();

  const [donuts, setDonuts] = useState<DonutEntity[]>([]);
  const settings = useGameStore((state) => state.settings);
  const currDonutSpeed = useGameStore((state) => state.currDonutSpeed);
  const user = useGlobalStore((state) => state.user);
  const appVisible = useGlobalStore((state) => state.appVisible);
  const gameFinished = useGameStore((state) => state.gameFinished);
  const addCurrCoins = useGameStore((state) => state.addCurrCoins);
  const increaseFailedDonuts = useGameStore((state) => state.increaseFailedDonuts);
  const setGameFinished = useGameStore((state) => state.setGameFinished);
  const setDonutsCleared = useGameStore((state) => state.setDonutsCleared);

  const requiredMinDonuts = useRef(25);
  const userFingerSize = parseInt(user.finger_size || '') || DEFAULT_FINGER_SLIDER_CONFIG.min;
  const currentCoinsCache = useRef(0);
  const donutDeleteQueue = useRef<string[]>([]);

  const onDonAnimationComplete = useCallback((id: string) => {
    donutDeleteQueue.current.push(id);
  }, []);

  const handleDonutClick = useCallback(
    (donut: DonutEntity) => {
      if (userFingerSize === donut.size) {
        currentCoinsCache.current += donut.value;

        if (
          currentCoinsCache.current + user.current_limit >=
          user.daily_limit + user.daily_bonus_limit
        ) {
          const diff =
            currentCoinsCache.current +
            user.current_limit -
            (user.daily_limit + user.daily_bonus_limit);

          const finalValue = donut.value - diff > 0 ? donut.value - diff : 0;

          addCurrCoins(finalValue);
          setGameFinished(true);
          return { isCorrect: true, value: finalValue };
        }

        addCurrCoins(donut.value);
        return { isCorrect: true, value: donut.value };
      }

      increaseFailedDonuts(1);
      return { isCorrect: false, value: 0 };
    },
    [userFingerSize],
  );

  const addDonut = useCallback(() => {
    const id = v4();
    const type = DONUT_TYPES[randomNumberBetween(0, DONUT_TYPES.length - 1)];
    const positionX = randomNumberBetween(0, window.innerWidth - 100);
    let size = DONUT_SIZES[randomNumberBetween(0, DONUT_SIZES.length - 1)];
    const value = getRandomValueWithChance(DONUT_AWARD_VALUES);

    if (getRandomBoolean() && getRandomBoolean() && requiredMinDonuts.current > 0) {
      size = userFingerSize as DonutSize;
      requiredMinDonuts.current -= 1;
    }

    startTransition(() =>
      setDonuts((prev) => {
        if (donutDeleteQueue.current.length > 0) {
          const filteredPrev = clearEntityQueue(prev, donutDeleteQueue);
          return [
            ...filteredPrev,
            { id, type, positionX, size, value, fallDuration: currDonutSpeed },
          ];
        }

        return [...prev, { id, type, positionX, size, value, fallDuration: currDonutSpeed }];
      }),
    );
  }, [userFingerSize, currDonutSpeed]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (gameFinished) {
        clearInterval(interval);
        setDonuts([]);
        setDonutsCleared(true);
        return;
      }
      if (!appVisible) {
        clearInterval(interval);
        return;
      }

      addDonut();
    }, settings.fall_donuts_interval * 1000);

    return () => clearInterval(interval);
  }, [gameFinished, appVisible, addDonut]);

  return {
    donuts,
    handleDonutClick,
    onDonAnimationComplete,
  };
};
