import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from './shared/constants';
import { I18NProvider, LazyMotion } from 'shared/libs';
import { useAppBackground } from 'shared/hooks';
import { TgBackButtonProvider } from 'components';
import { lazy } from 'react';
import { MainScreen } from 'screens';
import { FullHeightLayout, MainLayout, GlobalLoadingLayout } from 'shared/ui/layouts';
import { useAuthAndFetchAppData } from 'shared/api';

const CongratulationScreen = lazy(() =>
  import('screens').then((module) => ({ default: module.CongratulationScreen })),
);
const MarketScreen = lazy(() =>
  import('screens').then((module) => ({ default: module.MarketScreen })),
);
const SettingsScreen = lazy(() =>
  import('screens').then((module) => ({ default: module.SettingsScreen })),
);
const ProgressScreen = lazy(() =>
  import('screens').then((module) => ({ default: module.ProgressScreen })),
);
const FriendsScreen = lazy(() =>
  import('screens').then((module) => ({ default: module.FriendsScreen })),
);
const GameSessionScreen = lazy(() =>
  import('screens').then((module) => ({ default: module.GameSessionScreen })),
);
const ChooseFingerScreen = lazy(() =>
  import('screens').then((module) => ({ default: module.ChooseFingerScreen })),
);
const ChooseLanguageScreen = lazy(() =>
  import('screens').then((module) => ({ default: module.ChooseLanguageScreen })),
);
const BakeryScreen = lazy(() =>
  import('screens').then((module) => ({ default: module.BakeryScreen })),
);
const LimitReachedScreen = lazy(() =>
  import('screens').then((module) => ({ default: module.LimitReachedScreen })),
);
const FaqScreen = lazy(() => import('screens').then((module) => ({ default: module.FaqScreen })));

const App = () => {
  useAppBackground();
  useAuthAndFetchAppData();

  return (
    <LazyMotion>
      <WebAppProvider>
        <I18NProvider>
          <TgBackButtonProvider>
            <Routes>
              <Route element={<GlobalLoadingLayout />}>
                <Route element={<FullHeightLayout />}>
                  <Route path={ROUTES.CHOOSE_LANGUAGE} element={<ChooseLanguageScreen />} />
                  <Route path={ROUTES.CHOOSE_FINGER} element={<ChooseFingerScreen />} />
                  <Route path={ROUTES.CONGRATULATIONS} element={<CongratulationScreen />} />
                  <Route path={ROUTES.SESSION} element={<GameSessionScreen />} />
                  <Route path={ROUTES.SETTINGS} element={<SettingsScreen />} />
                  <Route path={ROUTES.LIMIT_REACHED} element={<LimitReachedScreen />} />
                  <Route path={ROUTES.FAQ} element={<FaqScreen />} />
                </Route>

                <Route path={ROUTES.MAIN} element={<MainLayout />}>
                  <Route index element={<MainScreen />} />
                  <Route path={ROUTES.BAKERY} element={<BakeryScreen />} />
                  <Route path={ROUTES.FRIENDS} element={<FriendsScreen />} />
                  <Route path={ROUTES.PROGRESS} element={<ProgressScreen />} />
                  <Route path={ROUTES.MARKET} element={<MarketScreen />} />
                </Route>
              </Route>
            </Routes>
          </TgBackButtonProvider>
        </I18NProvider>
      </WebAppProvider>
    </LazyMotion>
  );
};

export default App;
