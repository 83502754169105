import styles from './style.module.scss';
import { Link } from 'react-router-dom';
import { ROUTES, GAME_COIN_SHORTCUT } from 'shared/constants';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { UserWidget } from 'components';
import { UITypography } from 'shared/ui';
import { formatLargeNumber } from 'shared/utils';
import { useGlobalStore } from 'shared/store';
import { useInitData } from '@vkruglikov/react-telegram-web-app';

export const AppHeader = () => {
  const user = useGlobalStore((state) => state.user);
  const [initDataUnsafe] = useInitData();

  const tgUser = initDataUnsafe?.user;

  return (
    <div className={styles.header}>
      <UserWidget username={tgUser?.username} />

      <div className={styles.innerContainer}>
        <div className={styles.balance}>
          <UITypography fontWeight="bold">{`${formatLargeNumber(user.coins)} ${GAME_COIN_SHORTCUT}`}</UITypography>
        </div>

        <Link to={ROUTES.SETTINGS} className={styles.settingsButton}>
          <SettingsIcon />
        </Link>
      </div>
    </div>
  );
};
