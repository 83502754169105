import styles from './style.module.scss';
import { AboutUsModal, BobeHero } from 'components';
import { UITypography, SelectOption, UISelect, UIButton } from 'shared/ui';
import { LANG_SELECT_OPTIONS, SUPPORTED_LANGUAGES, ROUTES, CS_KEYS } from 'shared/constants';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCloudStorage } from '@vkruglikov/react-telegram-web-app';
import { Scale, Swipe } from 'shared/ui/animations';

export const ChooseLanguageModule = () => {
  const [selectedLang, setSelectedLang] = useState(LANG_SELECT_OPTIONS[0].value);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { setItem } = useCloudStorage();

  const handleLangChange = (selectedOption: SelectOption) => {
    setSelectedLang(selectedOption.value as SUPPORTED_LANGUAGES);
  };

  const goToChooseFinger = async () => {
    try {
      i18n.changeLanguage(selectedLang);
      await setItem(CS_KEYS.LANGUAGE, selectedLang);
      navigate(ROUTES.CHOOSE_FINGER);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.container}>
      <Scale>
        <BobeHero />
      </Scale>

      <Swipe direction="horizontal" from="right">
        <UITypography component="p" className={styles.subheader}>
          {t('choose_language_screen.subtitle')}
        </UITypography>
      </Swipe>
      <Swipe direction="horizontal" from="left" style={{ width: '100%', maxWidth: 400 }}>
        <UISelect
          options={LANG_SELECT_OPTIONS}
          onChange={handleLangChange}
          placeholder={t('choose_language_screen.select_language')}
          className={styles.select}
          defaultValue={LANG_SELECT_OPTIONS[0]}
        />
      </Swipe>
      <Swipe direction="horizontal" from="right" style={{ width: '100%', maxWidth: 400 }}>
        <UIButton fullwidth onClick={goToChooseFinger} className={styles.button}>
          {t('common.continue')}
        </UIButton>
      </Swipe>

      <Swipe direction="vertical" from="bottom">
        <AboutUsModal />
      </Swipe>
    </div>
  );
};
