import styles from './style.module.scss';
import { UIModal, UIModalProps, UITypography } from 'shared/ui';

type Props = UIModalProps & {
  title: string;
  img: string;
};

export const SuccessEnterLeagueModal = ({ img, onClose, title, visible }: Props) => {
  return (
    <UIModal visible={visible} onClose={onClose} detent="content-height">
      <div className={styles.root}>
        <img src={img} alt="" width={80} height={80} />
        <UITypography variant="H1">{title}</UITypography>
      </div>
    </UIModal>
  );
};
