import styles from './style.module.scss';
import { CurrentLeague, InviteFriendsButton, ReferralsList } from 'components';
import { Swipe } from 'shared/ui/animations';

export const FriendsModule = () => {
  return (
    <div className={styles.root}>
      <Swipe direction="vertical" from="top">
        <CurrentLeague />
      </Swipe>

      <ReferralsList />

      <Swipe direction="horizontal" from="right">
        <InviteFriendsButton />
      </Swipe>
    </div>
  );
};
